<div class="container-fluid">
  <div class="row mt-4 clearfix">
    <div class="offset-md-4 offset-lg-4 mt-5 mb-5 h-100 col-md-4 col-lg-4 container fix-gutter">
      <div class="row mat-elevation-z2">

        <form novalidate class="w-100" *ngIf="verifySuccess">
          <div class="col-12 heading mt-2 mb-2">
            <span class="icon mt-2">
              <mat-icon svgIcon="verified" i18n-matTooltip matTooltip="Successful"></mat-icon>
            </span>
            <span class="title">
              <h2 i18n>Fund PIN Confirmed</h2>
            </span>
          </div>
          <div class="col-12 heading mt-2 mb-4" i18n>
            Your Fund PIN has now been confirmed and enabled. You will be required to enter this PIN when performing withdrawals.
          </div>
          <div class="col-12 heading mt-2 mb-2">
            <a routerLink="/">
              <button mat-raised-button color="primary" class="w-100" i18n>CONTINUE</button>
            </a>
          </div>
        </form>

        <form novalidate class="w-100" *ngIf="!verifySuccess">
          <div class="col-12 heading mt-2 mb-2">
            <span class="icon mt-2px">
              <mat-icon svgIcon="warning" i18n-matTooltip matTooltip="Warning"></mat-icon>
            </span>
            <span class="title">
              <h2 i18n>Fund PIN</h2>
            </span>
          </div>
          <div class="col-12 heading mt-2 mb-4" i18n>
            This link has already been used or its request has expired. Please try creating a new Fund PIN.
          </div>
          <div class="col-12 heading mt-2 mb-4" i18n>
            If you are still having issues please Contact <a target="_blank" href="{{supportURL}}">support</a>.
          </div>
          <div class="col-12 heading mt-2 mb-2">
            <a routerLink="/">
              <button mat-raised-button color="primary" class="w-100" i18n>CONTINUE</button>
            </a>
          </div>
        </form>

      </div>
    </div>
  </div>
</div>
