<div class="container-fluid px-0">
  <div class="blueprint-background">
    <!-- start blueprint background -->
    <div class="row mt-4 clearfix">
      <div class="offset-md-4 offset-lg-4 mt-5 mb-5 h-100 col-md-4 col-lg-4 container fix-gutter">
        <div class="row mat-elevation-z2">

          <form novalidate class="w-100" *ngIf="successful">
            <div class="col-12 heading mt-2 mb-2">
              <span class="icon mt-2">
                  <mat-icon svgIcon="verified" i18n-matTooltip matTooltip="Successful" ></mat-icon>
              </span>
              <span class="title">
                <h2 i18n>Account Reactivated</h2>
              </span>
            </div>
            <div class="col-12 heading mt-2 mb-4">
              <h5 i18n>Your account has been reactivated successfully.</h5>
              <small i18n>Welcome back! Click the link below to log in to your account.</small>
            </div>
            <div class="col-12 heading mt-2 mb-2">
              <a routerLink="/login">
                <button mat-raised-button color="primary" class="w-100" i18n>LOG IN</button>
              </a>
            </div>
          </form>

          <form novalidate class="w-100" *ngIf="!successful">
            <div class="col-12 heading mt-2 mb-2">
              <span class="icon mt-2px">
                  <mat-icon svgIcon="warning" i18n-matTooltip matTooltip="Warning" ></mat-icon>
              </span>
              <span class="title">
                <h2 i18n>Reactivated Account</h2>
              </span>
            </div>
            <div class="col-12 heading mt-2 mb-4" i18n>
                This confirmation link has already been used or its request has expired. Please try again or contact support.
            </div>
            <div class="col-12 heading mt-2 mb-2">
              <a routerLink="/">
                <button mat-raised-button color="primary" class="w-100" i18n>HOME</button>
              </a>
            </div>
          </form>

        </div>
      </div>
    </div>
  </div>
</div>
