<div class="container-fluid">
  <div class="row col-12 mt-4 clearfix">
    <div class="offset-md-4 offset-lg-4 mt-5 mb-5 h-100 col-md-4 col-lg-4 container fix-gutter">
      <div class="row mat-elevation-z2" *ngIf="!!addressDetails || !!error">
        <!-- Success View -->
        <form novalidate class="col-12" *ngIf="successful">
          <div class="row">
            <!-- Header -->
            <div class="col-12 heading mt-2 mb-2">
              <span class="icon mt-2">
                <mat-icon svgIcon="verified"></mat-icon>
              </span>
              <span class="title">
                <h2 i18n>Trusted Address Confirmed</h2>
              </span>
            </div>
            <!-- Flavour text -->
            <div class="col-12 heading mt-2 mb-4">
              <h4 i18n>The following trusted address book entry has been verified.</h4>
            </div>

            <!-- Address details -->
            <ng-container *ngIf="confirmFiat; else fiat">
              <div class="info col-3 col-lg-3 col-md-4 mt-1 mb-1">
                <b i18n>Wallet:</b>
              </div>
              <div class="info col-9 col-lg-9 col-md-8 col mt-1 mb-1">
                {{addressDetails.coin}}
              </div>
              <div class="info col-3 col-lg-3 col-md-4 mt-1 mb-1">
                <b i18n>Name:</b>
              </div>
              <div class="info col-9 col-lg-9 col-md-8 col mt-1 mb-1">
                {{addressDetails.name}}
              </div>
              <div class="addresslabel info col-3 col-lg-3 col-md-12 mt-1 mb-1">
                <b i18n>Address:</b>
              </div>
              <div class="addressinfo info col-9 col-lg-9 col-md-12 mt-1 mb-1 overflowWrap" i18n>
                {{addressDetails.address}}
              </div>
              <ng-container *ngIf="addressDetails.paymentId">
                <div class="addresslabel info col-3 col-lg-3 col-md-12 mt-1 mb-1">
                  <b i18n>Payment ID:</b>
                </div>
                <div class="addressinfo info col-9 col-lg-9 col-md-12 mt-1 mb-1 overflowWrap" i18n>
                  {{addressDetails.paymentId}}
                </div>
              </ng-container>
            </ng-container>
            <ng-template #fiat>
              <div class="info col-3 col-lg-3 col-md-4 mt-1 mb-1">
                <b i18n>Name:</b>
              </div>
              <div class="info col-9 col-lg-9 col-md-8 col mt-1 mb-1">
                {{addressDetails.name}}
              </div>
              <div class="info col-3 col-lg-3 col-md-4 mt-1 mb-1">
                <b i18n>Bank:</b>
              </div>
              <div class="info col-9 col-lg-9 col-md-8 col mt-1 mb-1">
                {{addressDetails.bankName}}
              </div>
              <div class="addresslabel info col-3 col-lg-3 col-md-12 mt-1 mb-1">
                <b i18n>Account Number:</b>
              </div>
              <div class="addressinfo info col-9 col-lg-9 col-md-12 mt-1 mb-1 overflowWrap" i18n>
                {{addressDetails.accountNumber}}
              </div>
              <div class="addresslabel info col-3 col-lg-3 col-md-12 mt-1 mb-1">
                <b i18n>Account Type:</b>
              </div>
              <div class="addressinfo info col-9 col-lg-9 col-md-12 mt-1 mb-1 overflowWrap" i18n>
                {{addressDetails.accountType}}
              </div>
              <div class="addresslabel info col-3 col-lg-3 col-md-12 mt-1 mb-1">
                <b i18n>Branch Code:</b>
              </div>
              <div class="addressinfo info col-9 col-lg-9 col-md-12 mt-1 mb-1 overflowWrap" i18n>
                {{addressDetails.branchCode}}
              </div>
            </ng-template>

            <!-- Button -->
            <div class="col-12 heading mt-2 mb-2">
              <a routerLink="/balances">
                <button mat-raised-button color="primary" class="w-100" i18n>CONTINUE</button>
              </a>
            </div>
          </div>
        </form>
        <!-- Expired view -->
        <form novalidate class="w-100" *ngIf="!successful">
          <!-- Header -->
          <div class="col-12 heading mt-2 mb-2">
            <span class="icon mt-2px">
              <mat-icon svgIcon="warning"></mat-icon>
            </span>
            <span class="title">
              <h2 i18n>Confirm Trusted Address</h2>
            </span>
          </div>
          <!-- Fail Reason -->
          <div class="col-12 heading mt-2 mb-4">
            {{error}}
          </div>
          <!-- Button -->
          <div class="col-12 heading mt-2 mb-2">
            <a routerLink="/">
              <button mat-raised-button color="primary" class="w-100" i18n>HOME</button>
            </a>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
