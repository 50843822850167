<div class="container-fluid blueprint-background">
  <div class="row mt-4 clearfix">
    <div class="offset-md-4 offset-lg-4 mt-5 mb-5 h-100 col-md-4 col-lg-4 container fix-gutter">
      <div class="row mat-elevation-z2" *ngIf="resultMessage">

        <form novalidate class="w-100">
          <div class="col-12 heading mt-2 mb-2">
            <span class="icon mt-2">
              <img *ngIf="unblockSuccess" src="/assets/{{siteName}}/images/icons/check.png" />
              <img *ngIf="!unblockSuccess" src="/assets/{{siteName}}/images/icons/warning.png" />
            </span>
            <span class="title">
              <h2 i18n>Unblock Account</h2>
            </span>
          </div>
          <div class="col-12 heading mt-2 mb-4" innerHTML="{{ resultMessage }}"></div>
          <div class="col-12 heading mt-2 mb-2">
            <a routerLink="/login">
              <button mat-raised-button color="primary" class="w-100" i18n>SIGN IN</button>
            </a>
          </div>
        </form>

      </div>
    </div>
  </div>
</div>
