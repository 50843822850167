import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../core/api/api.service';
import { MarketingSettings } from '../core/interfaces/marketing-settings';

@Injectable({
  providedIn: 'root'
})
export class RequestConfirmService {

  constructor(
    private apiService: ApiService
  ) { }

  confirmRequest(hash: string, type: string = '') {
    const params = type ? {hash, type} : {hash};
    return this.apiService.call<any>('confirmRequest', params);
  }

}
