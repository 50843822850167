<ng-container *ngIf="validHash && !!response; else invalidHash">
    <div class="col-12">
        <app-accountreactivated *ngIf="response.type == 'reactivate'" [response]="response"></app-accountreactivated>
        <app-accountdeactivated *ngIf="response.type == 'deactivate'" [response]="response"></app-accountdeactivated>
        <app-confirm-fund-pin *ngIf="response.type == 'fund-pin'" [response]="response"></app-confirm-fund-pin>
        <app-confirm-ip *ngIf="response.type == 'confirm-ip'" [response]="response"></app-confirm-ip>
        <app-confirm-withdraw-settings *ngIf="response.type == 'withdraw-settings'" [response]="response"></app-confirm-withdraw-settings>
        <app-unblock *ngIf="response.type == 'unblock'" [response]="response"></app-unblock>
        <app-confirm-trusted-address *ngIf="response.type == 'addressbook-confirm'" [response]="response"></app-confirm-trusted-address>
        <app-confirm-api-setting *ngIf="response.type == 'api-setting'" [response]="response"></app-confirm-api-setting>
    </div>
</ng-container>

<ng-template #invalidHash>
    <div class="container-fluid px-0">
        <!-- start blueprint background -->
        <div class="blueprint-background">
            <div class="row col-12 mt-4 clearfix">
                <div class="offset-md-4 offset-lg-4 mt-5 mb-5 h-100 col-md-4 col-lg-4 container fix-gutter">
                    <div class="row mat-elevation-z2" *ngIf="response.reason">

                        <form novalidate class="w-100">
                            <div class="col-12 heading mt-2 mb-2">
                                <span class="icon mt-2px">
                                    <mat-icon class="red-icon" svgIcon="warning" i18n-matTooltip matTooltip="Warning"></mat-icon>
                                </span>
                                <span class="title">
                                    <h2 i18n>Confirmation Error</h2>
                                </span>
                            </div>
                            <div class="col-12 heading mt-2 mb-4">
                                This confirmation link has already been used, has expired or does not relate to the account you are currently signed in to.
                                Please try again or <a href="{{supportUrl}}" target="_blank">contact support.</a>
                            </div>
                            <div class="col-12 heading mt-2 mb-2">
                                <ng-container *ngIf="loggedIn; else notLoggedIn">
                                    <a routerLink="/">
                                        <button mat-raised-button color="primary" class="w-100" i18n>HOME</button>
                                    </a>
                                </ng-container>
                                <ng-template #notLoggedIn>
                                    <a routerLink="/login">
                                        <button mat-raised-button color="primary" class="w-100" i18n>SIGN IN</button>
                                    </a>
                                </ng-template>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
