import { Router } from '@angular/router';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SessionStorageService } from '../../core/services/session-storage.service';

@Component({
  selector: 'app-accountdeactivated',
  templateUrl: './accountdeactivated.component.html',
  styleUrls: ['./accountdeactivated.component.scss']
})

export class AccountDeactivatedComponent implements OnInit, OnDestroy {
  @Input() response: any = [];

  successful: Boolean;
  softLogout: Boolean = false;

  constructor(
    private router: Router,
    private sessionService: SessionStorageService
  ) {}

  ngOnInit() {
    if (!!this.response && this.response.response === 'success') {
      this.successful = true;
      this.softLogout = true;
      this.sessionService.remove('TOKEN');
      this.sessionService.store('LOGGED_IN', 'SOFT_LOGOUT');
      this.sessionService.store('LOGGED_OUT_REASON', 'GENERAL_NOTLOGGEDIN_SESSION_DEACTIVATED');
      this.sessionService.store('API_ERROR_LOGOUT', true);
    } else if (!this.response || this.response.response === 'failure') {
      this.successful = false;
    }
  }

  ngOnDestroy() {
    // If home button not clicked, logout and show deactivated message
    if (this.softLogout) {
      this.sessionService.store('LOGGED_IN', false);
      this.router.navigate(['/login']);
    }
  }

  logout() {
    this.softLogout = false;
    this.sessionService.purge();
    this.router.navigate(['/']);
  }

  openProfile() {
    this.router.navigate(['/profile']);
  }

}
