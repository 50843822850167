<div class="container-fluid blueprint-background">
    <div class="row w-100 mt-4 clearfix">
        <div class="mx-auto mt-5 mb-5 h-100 mw-md col-5 container fix-gutter">
            <div class="row mat-elevation-z2">
                <form novalidate class="col-12" *ngIf="successful">
                    <ng-container *ngIf="response.apiSettingType !== 'edit'; else editApi">
                        <div class="row">
                            <div class="col-12 heading mt-2 mb-2">
                                <span class="icon mt-2">
                                    <mat-icon svgIcon="verified" i18n-matTooltip matTooltip="Successful"></mat-icon>
                                </span>
                                <span class="title">
                                    <h2 i18n>API Setting Confirmed</h2>
                                </span>
                            </div>
                            <div class="col-12 heading mt-2 mb-4 px-4">
                                <p i18n>
                                    Your API Setting has been confirmed and is now ready for use. Please find the Private and Public Keys generated for it below.
                                </p>
                                <p i18n>
                                    Please keep these Keys safe and do not share them. It is advised that the Private Key is noted and kept safe since you will not be able to view it again once this screen is closed.
                                </p>
                            </div>
                        </div>

                        <div class="row px-0 mx-auto col-12 info">
                            <div class="col-3 info-key"><strong i18n>API Name: </strong></div>
                            <div class="col overflowWrap">{{ response.name }}</div>
                        </div>
                        <div class="row info px-0 mx-auto">
                            <div class="col-3 info-key"><strong i18n>Private Key: </strong></div>
                            <div class="col-8 overflowWrap">
                                {{ response.privateKey }}
                            </div>
                            <div class="col-1">
                                <mat-icon color="primary" (click)="copyToClipboard(response.privateKey)" i18n-matTooltip matTooltip="Copy Private Key">file_copy</mat-icon>
                            </div>
                        </div>
                        <div class="row info px-0 mx-auto">
                            <div class="col-3 info-key"><strong i18n>Public Key: </strong></div>
                            <div class="col-8 overflowWrap">
                                {{ response.publicKey }}
                            </div>
                            <div class="col-1">
                                <mat-icon color="primary" (click)="copyToClipboard(response.publicKey)" i18n-matTooltip matTooltip="Copy Public Key">file_copy</mat-icon>
                            </div>
                        </div>
                        <div class="row info px-0 mx-auto"></div>
                    </ng-container>

                    <ng-template #editApi>
                        <div class="row">
                            <div class="col-12 heading mt-2 mb-2">
                                <span class="icon mt-2">
                                    <mat-icon svgIcon="verified" i18n-matTooltip matTooltip="Successful"></mat-icon>
                                </span>
                                <span class="title">
                                    <h2 i18n>API Setting Confirmed</h2>
                                </span>
                            </div>
                            <div class="col-12 heading mt-2 mb-4" i18n>
                                Your API Setting has been confirmed and is now ready for use.
                            </div>
                        </div>
                    </ng-template>

                    <div class="col-12 heading mt-2 mb-2">
                        <a routerLink="/settings">
                            <button mat-raised-button color="primary" class="w-100" i18n>CONTINUE</button>
                        </a>
                    </div>
                </form>

                <form novalidate class="w-100" *ngIf="!successful">
                    <div class="col-12 heading mt-2 mb-2">
                        <span class="icon mt-2px">
                            <mat-icon svgIcon="warning" i18n-matTooltip matTooltip="Warning"></mat-icon>
                        </span>
                        <span class="title">
                            <h2 i18n>API Setting Confirm</h2>
                        </span>
                    </div>

                    <div class="col-12 heading mt-2 mb-4" [innerHTML]="response.reason|i18n"></div>

                    <div class="col-12 heading mt-2 mb-2">
                        <a routerLink="/">
                            <button mat-raised-button color="primary" class="w-100" i18n>HOME</button>
                        </a>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
