import { Component, OnInit, Input } from '@angular/core';
import { environment } from './../../../environments/environment';

@Component({
  selector: 'app-confirm-ip',
  templateUrl: './confirm-ip.component.html',
  styleUrls: ['./confirm-ip.component.scss']
})
export class ConfirmIpComponent implements OnInit {
  @Input() response: any = {};

  ip: string;
  verifySuccess: boolean = true;
  supportURL: string = environment.config.SUPPORT_URL;

  constructor() {}

  ngOnInit() {
    if (this.response.response === 'success') {
      this.verifySuccess = true;
      this.ip = this.response.data.ip;
    } else if (this.response.response === 'failure') {
      this.verifySuccess = false;
    }
  }

}
