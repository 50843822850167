import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MetaGuard } from '@ngx-meta/core';

import { environment } from '../../environments/environment';
import { AuthGuard, NoAuthGuard } from '../core/services/auth-guard.service';
import { RequestConfirmComponent } from './request-confirm.component';
import { RequestConfirmModule } from './request-confirm.module';

const routes: Routes = [
  { path: 'login/unblock', component: RequestConfirmComponent, data: {
    title: 'Unblock Account',
    meta: {
      title: environment.config.EXCHANGE_NAME + ' - Unblock Account',
      description: environment.config.EXCHANGE_NAME
    } }, canActivate: [NoAuthGuard, MetaGuard] },
  { path: 'login/unblock/:hash', component: RequestConfirmComponent, data: {
    title: 'Unblock Account',
    meta: {
      title: environment.config.EXCHANGE_NAME + ' - Unblock Account',
      description: environment.config.EXCHANGE_NAME
    } }, canActivate: [NoAuthGuard, MetaGuard] },
  { path: 'login/authorise/ip/:hash', component: RequestConfirmComponent, data: {
    title: 'Authorise IP',
    meta: {
      title: environment.config.EXCHANGE_NAME + ' - Authorise IP',
      description: environment.config.EXCHANGE_NAME
    } }, canActivate: [NoAuthGuard, MetaGuard] },
  { path: 'deactivate/confirm', component: RequestConfirmComponent, data: {
    title: 'Account Deactivation',
    meta: {
      title: environment.config.EXCHANGE_NAME + ' - Account Deactivation',
      description: environment.config.EXCHANGE_NAME
    } }, canActivate: [AuthGuard, MetaGuard] },
  { path: 'deactivate/confirm/:hash', component: RequestConfirmComponent, data: {
    title: 'Account Deactivation',
    meta: {
      title: environment.config.EXCHANGE_NAME + ' - Account Deactivation',
      description: environment.config.EXCHANGE_NAME
    } }, canActivate: [MetaGuard] },
  { path: 'activate/confirm', component: RequestConfirmComponent, data: {
    title: 'Account Reactivation',
    meta: {
      title: environment.config.EXCHANGE_NAME + ' - Account Reactivation',
      description: environment.config.EXCHANGE_NAME
    } }, canActivate: [NoAuthGuard, MetaGuard] },
  { path: 'activate/confirm/:hash', component: RequestConfirmComponent, data: {
    title: 'Account Reactivation',
    meta: {
      title: environment.config.EXCHANGE_NAME + ' - Account Reactivation',
      description: environment.config.EXCHANGE_NAME
    } }, canActivate: [NoAuthGuard, MetaGuard] },
  { path: 'settings/confirm-withdraw/:hash', component: RequestConfirmComponent, data: {
      title: 'Confirm Withdrawal Settings',
      meta: {
        title: environment.config.EXCHANGE_NAME + ' - Confirm Withdrawal Settings',
        description: environment.config.EXCHANGE_NAME
      } }, canActivate: [ AuthGuard, MetaGuard] },
  { path: 'settings/confirm-funds-pin/:hash', component: RequestConfirmComponent, data: {
      title: 'Confirm Withdrawal Settings',
      meta: {
        title: environment.config.EXCHANGE_NAME + ' - Confirm Fund PIN',
        description: environment.config.EXCHANGE_NAME
      }
    }, canActivate: [AuthGuard, MetaGuard] },
  { path: 'addressbook/confirm/:hash', component: RequestConfirmComponent, data: {
    title: 'Trusted Address Confirmation',
    meta: {
      title: environment.config.EXCHANGE_NAME + ' - Trusted Address Confirmation',
      description: environment.config.EXCHANGE_NAME
    } }, canActivate: [AuthGuard, MetaGuard] },
  { path: 'addressbook/bank/confirm/:hash', component: RequestConfirmComponent, data: {
    title: 'Trusted Address Confirmation',
    meta: {
      title: environment.config.EXCHANGE_NAME + ' - Trusted Address Confirmation',
      description: environment.config.EXCHANGE_NAME
    } }, canActivate: [AuthGuard, MetaGuard] },
  { path: 'settings/confirm-api/:type/:hash', component: RequestConfirmComponent, data: {
    title: 'Confirm API Settings',
    meta: {
      title: environment.config.EXCHANGE_NAME + ' - Confirm API Settings',
      description: environment.config.EXCHANGE_NAME
    } }, canActivate: [AuthGuard, MetaGuard] },
];

export const routing: ModuleWithProviders<RequestConfirmModule> = RouterModule.forChild(routes);
