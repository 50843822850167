<div class="container-fluid px-0">
  <div class="blueprint-background">
    <!-- start blueprint background -->
    <div class="row mt-4 clearfix">
      <div class="offset-md-4 offset-lg-4 mt-5 mb-5 h-100 col-md-4 col-lg-4 container fix-gutter">
        <div class="row mat-elevation-z2">

          <form novalidate class="w-100" *ngIf="successful">
            <div class="col-12 heading mt-2 mb-2">
              <span class="icon mt-2">
                <mat-icon class="red-icon" svgIcon="verified" i18n-matTooltip matTooltip="Successful"></mat-icon>
              </span>
              <span class="title">
                <h2 i18n>Account Deactivated</h2>
              </span>
            </div>
            <div class="col-12 heading mt-2 mb-4" i18n>
              Your account has been deactivated successfully. We are sorry to see you go, you may reactivate your account
              at any time by logging back into your account from the login page.
            </div>
            <div class="col-12 heading mt-2 mb-2">
              <button mat-raised-button color="primary" (click)="logout()" class="w-100" i18n>HOME</button>
            </div>
          </form>

          <form novalidate class="w-100" *ngIf="!successful">
            <div class="col-12 heading mt-2 mb-2">
              <span class="icon mt-2px">
                <mat-icon class="red-icon" svgIcon="warning" i18n-matTooltip matTooltip="Warning"></mat-icon>
              </span>
              <span class="title">
                <h2 i18n>Deactivated Account</h2>
              </span>
            </div>
            <div class="col-12 heading mt-2 mb-4" i18n>
              This confirmation link has already been used or its request has expired. Please try again or contact
              support.
            </div>
            <div class="col-12 heading mt-2 mb-2">
              <button mat-raised-button color="primary" (click)="openProfile()" class="w-100">HOME</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
