import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-confirm-api-setting',
  templateUrl: './confirm-api-setting.component.html',
  styleUrls: ['./confirm-api-setting.component.scss']
})
export class ConfirmApiSettingComponent implements OnInit {
  @Input() response: any = {};

  successful: Boolean = false;

  constructor(
    private i18n: I18n,
    public snackbar: MatSnackBar,
    private clipboard: ClipboardService
  ) { }

  ngOnInit() {
    if (this.response.response === 'success') {
      this.successful = true;
    } else if (this.response.response === 'failure') {
      this.successful = false;
    }
  }

  copyToClipboard(str: string) {
    this.clipboard.copyFromContent(str);
    this.snackbar.open(this.i18n('Copied') + ': ' + str, this.i18n('Close'), { duration: 2000 });
  }

}
