import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-accountreactivated',
  templateUrl: './accountreactivated.component.html',
  styleUrls: ['./accountreactivated.component.scss']
})
export class AccountreactivatedComponent implements OnInit {
  @Input() response: any = {};

  successful: Boolean = false;

  constructor() {}

  ngOnInit() {
    if (this.response.response === 'success') {
      this.successful = true;
    } else if (this.response.response === 'failure') {
      this.successful = false;
    }
  }

}
