import { Component, OnInit, Input } from '@angular/core';
import { I18nTranslationService } from 'src/app/core/services/i18n-translation.service';
import { environment } from '../../../environments/environment';

/**
 *
 *
 * @export
 * @class UnblockComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-unblock',
  templateUrl: './unblock.component.html',
  styleUrls: ['./unblock.component.scss']
})

export class UnblockComponent implements OnInit {
  @Input() response: any = {};

  unblockSuccess: boolean;
  resultMessage: string;

  siteName: string = environment.config.EXCHANGE_NAME_L;

  constructor(
    private translateService: I18nTranslationService
  ) {}

  ngOnInit() {
    this.unblockSuccess = this.response.response === 'success';
    this.resultMessage = this.translateService.translateResponse(this.response.reason);
  }

}
