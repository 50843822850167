import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from '../../environments/environment';
import { SharedModule } from '../shared/shared.module';
import { AuthGuard, NoAuthGuard } from '../core/services/auth-guard.service';

import { routing } from './request-confirm.routing';
import { RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { RequestConfirmComponent } from './request-confirm.component';
import { AccountDeactivatedComponent } from './accountdeactivated/accountdeactivated.component';
import { AccountreactivatedComponent } from './accountreactivated/accountreactivated.component';
import { UnblockComponent } from './unblock/unblock.component';
import { ConfirmIpComponent } from './confirm-ip/confirm-ip.component';
import { UnblockaccountComponent } from './unblockaccount/unblockaccount.component';
import { ConfirmFundsPinComponent } from './confirm-fund-pin/confirm-fund-pin.component';
import { ConfirmWithdrawSettingsComponent } from './confirm-withdraw-settings/confirm-withdraw-settings.component';
import { ConfirmTrustedAddressComponent } from './confirm-trusted-address/confirm-trusted-address.component';
import { ConfirmApiSettingComponent } from './confirm-api-setting/confirm-api-setting.component';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    routing,
    RecaptchaModule,
    RecaptchaFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatButtonModule,
    SharedModule,
    MatIconModule,
    MatSlideToggleModule,
  ],
  declarations: [
    RequestConfirmComponent,
    UnblockaccountComponent,
    AccountDeactivatedComponent,
    AccountreactivatedComponent,
    UnblockComponent,
    ConfirmIpComponent,
    ConfirmFundsPinComponent,
    ConfirmWithdrawSettingsComponent,
    ConfirmTrustedAddressComponent,
    ConfirmApiSettingComponent
  ],
  providers: [
    AuthGuard,
    NoAuthGuard,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.config.RECAPTCHA_SITE_KEY } as RecaptchaSettings,
    }
  ],
  exports: [ReactiveFormsModule, RecaptchaModule]
})
export class RequestConfirmModule { }
