<div class="container-fluid">
  <div class="row mt-4 clearfix">
    <div class="offset-md-4 offset-lg-4 mt-5 mb-5 h-100 col-md-4 col-lg-4 container fix-gutter">
      <div class="row mat-elevation-z2">

        <form novalidate class="w-100" *ngIf="successful">
          <div class="col-12 heading mt-2 mb-2">
            <span class="icon mt-2">
                <mat-icon svgIcon="verified" i18n-matTooltip matTooltip="Successful" ></mat-icon>
            </span>
            <span class="title">
              <h2 i18n>Withdrawal Settings Confirmed</h2>
            </span>
          </div>
          <div class="col-12 heading mt-2 mb-4" i18n>
            Your withdrawal settings have been confirmed.
          </div>
          <div class="col-12 heading mt-2 mb-2">
            <a routerLink="/">
              <button mat-raised-button color="primary" class="w-100" i18n>HOME</button>
            </a>
          </div>
        </form>

        <form novalidate class="w-100" *ngIf="!successful">
          <div class="col-12 heading mt-2 mb-2">
            <span class="icon mt-2px">
              <mat-icon svgIcon="warning" i18n-matTooltip matTooltip="Warning" ></mat-icon>
            </span>
            <span class="title">
              <h2 i18n>Withdrawal Settings</h2>
            </span>
          </div>
          <div class="col-12 heading mt-2 mb-4" i18n>
            This link has already been used or its request has expired. Please try changing your withdrawal settings again or contact support.
          </div>
          <div class="col-12 heading mt-2 mb-2">
            <a routerLink="/settings">
              <button mat-raised-button color="primary" class="w-100" i18n>SETTINGS</button>
            </a>
          </div>
        </form>

      </div>
    </div>
  </div>
</div>
