
import { Component, Input, OnInit } from '@angular/core';
import { I18nTranslationService } from 'src/app/core/services/i18n-translation.service';
// Local
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-confirm-trusted-address',
  templateUrl: './confirm-trusted-address.component.html',
  styleUrls: ['./confirm-trusted-address.component.scss']
})
export class ConfirmTrustedAddressComponent implements OnInit {
  @Input() response: any = {};

  exchangeName: string = environment.config.EXCHANGE_NAME_L;
  successful: Boolean;
  addressDetails: any = {};
  error: string = '';
  confirmFiat: boolean = true;

  constructor(
    private _translateService: I18nTranslationService,
  ) { }

  ngOnInit(): void {
    if (this.response.response === 'success') {
      this.successful = true;
      this.addressDetails = this.response.data;
      this.confirmFiat = (this.addressDetails.accountNumber === undefined);
    } else {
      this.successful = false;
      this.error = this._translateService.translateResponse(this.response.reason);
    }
  }

}
