import { Component, Input, OnInit } from '@angular/core';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';

@Component({
  selector: 'app-confirm-withdraw-settings',
  templateUrl: './confirm-withdraw-settings.component.html',
  styleUrls: ['./confirm-withdraw-settings.component.scss']
})
export class ConfirmWithdrawSettingsComponent implements OnInit {
@Input() response: any = [];

  hash: string;
  successful: Boolean = false;

  constructor(
    private session: SessionStorageService,
  ) {}

  ngOnInit() {
    if (this.response.response === 'success') {
      if (this.response.withdrawSettings) {
        this.session.store('WITHDRAW_SETTINGS', this.response.withdrawSettings);
      }
      this.successful = true;
    } else if (this.response.response === 'failure') {
      this.successful = false;
    }
  }

}
