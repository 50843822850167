import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RouterHelper } from '../core/router-helper';
import { I18nTranslationService } from '../core/services/i18n-translation.service';
import { SessionStorageService } from '../core/services/session-storage.service';
import { RequestConfirmService } from './request-confirm.service';
import { environment } from './../../environments/chainex/environment.prod';

@Component({
  selector: 'app-request-confirm',
  templateUrl: './request-confirm.component.html',
  styleUrls: ['./request-confirm.component.scss']
})
export class RequestConfirmComponent implements OnInit {

  hash: string;
  loggedIn: boolean;
  validHash: boolean = false;
  valiType: boolean = true;
  apiSettingType: string = '';
  response: any = {};
  supportUrl: string = environment.config.SUPPORT_URL;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private sessionStorage: SessionStorageService,
    private service: RequestConfirmService,
    private translateService: I18nTranslationService
  ) {
    this.hash = '';
    this.loggedIn = (this.sessionStorage.get('LOGGED_IN') === 'logged_in');

    this.route.params.subscribe(params => {
      this.hash = params['hash'];
      if (params['type']) {
        if (['generate', 'edit', 'regenerate'].includes(params['type'].toLowerCase())) {
          this.apiSettingType = params['type'];
        } else {
          this.valiType = false;
          this.router.navigate(['404']);
        }
      }
    });

    if (!this.hash || this.hash === '') {
      this.router.navigate(this.loggedIn ? ['/markets'] : ['/login']);
    }
  }

  ngOnInit() {
    if (this.valiType) {
      this.confirmRequest();
    }
  }

  confirmRequest() {
    this.service.confirmRequest(this.hash, this.apiSettingType).subscribe(res => {
      if (res.reason === 'GENERAL_NOTLOGGEDIN' && !!res.type) {
        RouterHelper.goToLogin(this.router, null);
      } else if (res.response === 'success' || !!res.type) {
        this.validHash = true;
        this.response = res;
        this.response.hash = this.hash;
        if (this.apiSettingType !== '' && res.type === 'api-setting') {
          this.response['apiSettingType'] = this.apiSettingType;
        }
      } else {
        this.validHash = false;
        this.response = res;
        this.response.reason = this.translateService.translateResponse(res.reason);
      }
    });
  }

}
