<div class="container-fluid">
  <div class="row mt-4 clearfix">
    <div class="offset-md-4 offset-lg-4 mt-5 mb-5 h-100 col-md-4 col-lg-4 container fix-gutter">
      <div class="row mat-elevation-z2">

        <form novalidate class="w-100" *ngIf="verifySuccess">
          <div class="col-12 heading mt-2 mb-2">
            <span class="icon mt-2">
              <mat-icon svgIcon="verified" i18n-matTooltip matTooltip="Successful" ></mat-icon>
            </span>
            <span class="title">
              <h2 i18n>IP Address Confirmed</h2>
            </span>
          </div>
          <div class="col-12 heading mt-2 mb-4" i18n>
            The IP address below has been marked as safe. You may safely continue logging in.
          </div>
          <hr>
          <div class="col-12 heading mt-2 mb-4" i18n>
            <span class="bold">IP Address: </span>
            <span>{{ip}}</span>
          </div>
          <div class="col-12 heading mt-2 mb-2">
            <a routerLink="/login">
              <button mat-raised-button color="primary" class="w-100" i18n>CONTINUE TO LOGIN</button>
            </a>
          </div>
        </form>

        <form novalidate class="w-100" *ngIf="!verifySuccess">
          <div class="col-12 heading mt-2 mb-2">
            <span class="icon mt-2px">
                <mat-icon svgIcon="warning" i18n-matTooltip matTooltip="Warning" ></mat-icon>
            </span>
            <span class="title">
              <h2 i18n>Confirm IP Address</h2>
            </span>
          </div>
          <div class="col-12 heading mt-2 mb-4" i18n>
            This IP address has already been confirmed or the email link used is invalid or has expired. Please attempt a new login.
          </div>
          <div class="col-12 heading mt-2 mb-4" i18n>
            If you are still having issues please Contact  <a target="_blank" href="{{supportURL}}">support</a>.
          </div>
          <div class="col-12 heading mt-2 mb-2">
            <a routerLink="/login">
              <button mat-raised-button color="primary" class="w-100" i18n>CONTINUE TO LOGIN</button>
            </a>
          </div>
        </form>

      </div>
    </div>
  </div>
</div>
