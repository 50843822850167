import { language } from './languages/language';

export const environment = {
  production: true,
  config: {
    env: {
      name: 'prod'
    },
    'EXCHANGE_NAME': 'ChainEX',
    'EXCHANGE_NAME_L': 'chainex',
    'BACKEND_URL': 'https://app.chainex.io',
    'RECAPTCHA_SITE_KEY': '6LdSgOUUAAAAANQFRYR0WxepRYATmxYIqaPbz2Ks',
    'APP_URL': 'https://chainex.io',
    'API_URL': 'https://api.chainex.io/',
    'SUPPORT_URL': 'https://support.chainex.io',
    'SUPPORT_IFRAME': 'https://support.chainex.io/open.php?iframe=true',
    'APP_TITLE': 'ChainEX - Tomorrow\'s Trading, Today!',
    'PUSH_SERVER': 'wss://push.chainex.io:443/',
    'JOB_EMAIL': '',
    'SUPPORT_EMAIL': 'support@chainex.io',
    'DEFAULT_MARKET_COIN_ID': '',
    'SOCIAL_*_URL': '',
    'BANK_NAME_FNB': 'FNB',
    'BANK_HOLDER_FNB': 'ChainEX',
    'BANK_ACCOUNT_FNB': '62741669884',
    'BANK_BRANCH_FNB': '250655',
    'BANK_TYPE_FNB': 'Current/Cheque',
    'BANK_NAME_STD': 'Standard Bank',
    'BANK_HOLDER_STD': 'CHAINEX (PTY) LTD ',
    'BANK_ACCOUNT_STD': '0000283394714',
    'BANK_BRANCH_STD': '051001',
    'BANK_TYPE_STD': 'Current/Cheque',
    'DISABLE_RECAPTCHA': true,
    'HEADER_STATS': true,
    'MARKET_PAIR': false,
    'ORDER_BOOK_HEADER': false,
    'DISABLE_MARKET_HISTORY': true,
    'AIRBRAKE_PROJECT_ID': '238189',
    'AIRBRAKE_PROJECT_KEY': '3100249a465f31ae36f14b8f49385918',
    'AIRBRAKE_ENVIRONMENT_TYPE': 'production',
    'INTO_THE_BLOCK_URL': 'https://app.intotheblock.com/widget.js',
    'INTO_THE_BLOCK_KEY': '2hn3QRsG1gaRQuAV3uPUz6h4BmdEmPHw6SIrUIPw',

    ADDRESS_DETAILS: {
      'NAME': 'ChainEX (Pty) Ltd',
      'STREET': ' 2A Da Gama Rd,',
      'TOWN': 'Jeffreys Bay, EC, 6330',
      'REGISTRATION': 'Company Registration Number: 2017/439541/07',
      'COUNTRY': '(South Africa)',
    },

    'BURNX_MARKET': false,
    'CHAINEX_MARKET': true,
    'CHAINEX_GROUPING': true,
    'BURNX_GROUPING': false,

    'ORDER_BOOK_COLOR': {
      'dark-theme': {
        'buy': '#e76464',
        'buy_dark' : '#c22828',
        'sell': '#69cc66',
        'sell_dark' : '#2EAE1C'
      },
      'light-theme': {
        'buy': '#fae0e0',
        'buy_dark' : '#f3b4b4',
        'sell': '#e1f5e0',
        'sell_dark' : '#AEE3AC'
      }
    },

    'FACEBOOK_LINK': 'https://www.facebook.com/ChainEXIO/',
    'TWITTER_LINK': 'https://twitter.com/ChainEXIO',
    'LINKEDIN_LINK': 'https://www.linkedin.com/company/chainexio/',
    'INSTAGRAM_LINK': 'https://www.instagram.com/chainexio/',
    'MEDIUM_LINK': 'https://medium.com/chainex',
    'GHOST_LINK' : 'https://blog.chainex.io/',
    'REDDIT_LINK': 'https://www.reddit.com/r/ChainEX/',
    'TELEGRAM_LINK': 'https://t.me/chainexchat',
    'YOUTUBE_LINK': 'https://www.youtube.com/channel/UC_3FaLikqQU46qLNr9HPoFw',

    language: language.language
  }
};
