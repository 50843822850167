import { Component, OnInit, NgModule, Input } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-confirm-fund-pin',
  templateUrl: './confirm-fund-pin.component.html',
  styleUrls: ['./confirm-fund-pin.component.scss']
})
export class ConfirmFundsPinComponent implements OnInit {
@Input() response: any = [];

  hash: string;
  ip: string;
  verifySuccess: boolean = true;
  supportURL: string = environment.config.SUPPORT_URL;

  constructor() {}

  ngOnInit() {
    if (this.response.response === 'success') {
      this.verifySuccess = true;
    } else if (this.response.response === 'failure') {
      this.verifySuccess = false;
    }
  }

}
